<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required, email, sameAs, requiredIf} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"

import {
	userMethods,
	groupMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
	page: {
		title: "Hosts",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		Multiselect,
		EmptyList
	},
	data() {
		return {
			title: "",
			items: [],
			showModal: false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,
			options: [
				"admin",
				"host",
			],
			users:[],
			groups: [],
			user:{
                role:'host'
            },
			submitted:false,
			confirmDelete:false,
			currentPage:1,
			perPage:8,
			rows:0,
			userId:null,
			empty_list_config:{
					"title" : this.$t("users.empty_search_host_title"),
					"linkButtonText" : this.$t("common.reset_search"),
					icon: "fa-cubes"
			},
			empty_config:{
					"title" : this.$t("users.empty_host_title"),
					"subtitle" : this.$t("users.empty_host_text"),
					"buttonText" : this.$t('users.new_host'),
					icon: "fa-cubes"
			},query:''
		};
	},
	validations: {
		user: {
			name: {
				required,
			},
			email: {
					required,
					email,
			},
			group: {
				// eslint-disable-next-line no-console
				required: requiredIf((model)=> { console.log(model);return model.role === "host" })
      
			},
			role: {
					required,
			},
			password: {
				required,
			},
			repeat_password: {
				required,
				sameAsPassword: sameAs('password')
			},
		}
	},
	
	mounted() {
		this.title =this.$t("users.title_host");
		this.loadUsers();
	},
	created(){
		this.debounceUsers = _debounce(this.searchUsers.bind(this), 1000);
	},
	methods: {
		...userMethods,
		...groupMethods,

		loadUsers(){
			this.isLoading = true;
			let loader = this.$loading.show();
			const params={
				q: `where[role]=host&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}&populate[0][path]=group&populate[0][select]=_id name`,
			}
			this.getUsers(params).then((users)=>{
				if(users&& users.data){
					this.user= {
                        role:'host'
                    };
					this.submitted= false;
					this.users= users.data;
					this.rows= users.totalCount
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

		onConfirmCreateUserClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			let loader = this.$loading.show();
			this.createUser(this.user).then(()=>{
				
				this.$notify({ type: 'success', text: this.$t("users.create_user_success"),title:  this.$t("users.new_host") });
				this.showModal= false;
				this.currentPage=1;
				this.loadUsers();

			}).catch((()=>{
				this.$notify({ type: 'error', text: this.$t("users.create_user_error"),title:  this.$t("users.new_host") });		
			})).finally(()=>{
				this.isLoading = false;
				loader.hide();
				this.submitted = false;
			});
		},

		onConfirmEditUserClicked(){
			this.submitted = true;
			if(!this.user.name || !this.user.email || !this.user.role || (!this.user.group && this.user.role == "host")) {
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				}
			}
			
			this.isLoading = true;
			let loader = this.$loading.show();
			this.updateUser(this.user).then(()=>{
				this.$notify({ type: 'success', text: this.$t("users.update_user_success"),title:  this.$t("users.update_host") });
				this.showModal= false;
				this.currentPage=1;
				this.loadUsers();
				this.user={
                    role:'host'
                }
			}).catch((()=>{
				this.$notify({ type: 'error', text: this.$t("users.update_user_error"),title:  this.$t("users.update_host") });		
			})).finally(()=>{
				this.isLoading = false;
				loader.hide();
				this.submitted = false;
			});
		},

		onCreateOrEditUserClicked() {
			if(this.user._id) {
				this.onConfirmEditUserClicked();
			}else {
				this.onConfirmCreateUserClicked();
			}
		},

		onDeleteUserClicked(userId){
			this.confirmDelete = true;
			this.userId = userId
		},

		onEditUserClicked(user){
			const cloneUser = Object.assign({}, user);
			this.user = cloneUser;
			this.loadGroups();
			this.showModal= true;
		},

		onConfirmRemoveUserClicked(){
			
				this.isLoading = true;
				let loader = this.$loading.show();
				this.deleteUser(this.userId).then(()=>{
						this.$notify({ type: 'success', text: this.$t("users.delete_user_success"),title:  this.$t("users.delete_host") });
						this.currentPage=1;
						this.loadUsers();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("users.delete_user_error"),title:  this.$t("users.delete_host") });		
				}).finally(()=>{
						this.isLoading = false;
						loader.hide();
						this.userId=null;
				})
		},

		onUserListPageClicked() {
				this.loadUsers();
		},

		onCancelCreateOrEditUserClicked() {
				this.showModal = false;
				this.user ={
                    role: 'host'
                };
				this.userId = null;
		},

		onCreateUserClicked(){
			this.user={
                role: 'host'
            };
			this.showModal = true;
			this.loadGroups();
		},

		loadGroups(){
			let loader = this.$loading.show();
			this.getGroups().then((groups)=>{
				if(groups&& groups.data){
					this.submitted= false;
					this.groups= groups.data;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

		searchUsers(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
			this.users=[];
			this.empty_list_config.subtitle =  this.$t("users.empty_search_host_text").replaceAll('#', this.query);
			if(query){
				this.currentPage = 1;
						const params={
							q: `where[role]=host&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}&populate[0][path]=group&populate[0][select]=_id name`,
						}
						this.getUsers(params).then((users)=>{
				if(users&& users.data){
								this.users= users.data;
								this.rows= users.totalCount;
				}
				}).catch(()=>{
							this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title_host") });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.currentPage = 1;
				loader.hide();
				this.loadUsers();
			}
		},
		onResetSearchClicked(){
			this.query = '';
      this.loadUsers();
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
			<div class="row">
					<div class="col-12">
						<div class="card mb-3">
						<div class="card-body">
								<div class="row ">
								<div class="col-sm-4">
										<div class="search-box me-2 mb-0 d-inline-block">
										<div class="position-relative">
												<input
												type="text"
												class="form-control"
												v-model="query"
												:placeholder="$t('common.search')"
												@input="debounceUsers($event.target.value)"
												/>
												<i class="bx bx-search-alt search-icon"></i>
										</div>
										</div>
								</div>
								<div class="col-sm-8">
										<div class="text-sm-end">
										<button
												type="button"
												class="btn btn-primary mb-0 me-0"
												@click="onCreateUserClicked"
												v-if="users.length>0 || query!=''">
												<i class="mdi mdi-plus me-1"></i> {{$t('users.new_host')}}
										</button>
										<b-modal
												v-model="showModal"
												:title="user._id ? $t('users.update_host') :$t('users.new_host')"
												title-class="text-black font-18"
												body-class="p-3"
												hide-footer
										>
												<form @submit.prevent="onCreateOrEditUserClicked">
												<div class="row">
														<div class="col-12">
														<div class="mb-3">
																<label for="name">{{$t('users.name')}}*</label>
																<input
																id="name"
																v-model="user.name"
																type="text"
																class="form-control"
																:placeholder="$t('users.name_placeholder')"
																:class="{
																		'is-invalid':
																		submitted && $v.user.name.$error,
																}"
																/>
																<div
																v-if="submitted && !$v.user.name.required"
																class="invalid-feedback">
                                                                    {{$t('users.name_required')}}
																</div>
														</div>
														</div>
														<div class="col-12">
														<div class="mb-3">
																<label for="email">{{$t('users.email')}}*</label>
																<input
																id="email"
																v-model="user.email"
																type="email"
																class="form-control"
																:placeholder="$t('users.email_placeholder')"
																:class="{
																		'is-invalid':
																		submitted && $v.user.email.$error,
																}"
																/>
																<div
																v-if="submitted && !$v.user.email.required"
																class="invalid-feedback"
																>
																{{$t('users.email_required')}}
																</div>
														</div>
														</div>
														<div class="col-12" v-if="user.role=='host'">
															<div class="mb-3">
																<label for="role">{{$t('users.group')}}*</label>
																	<multiselect 
																		v-model="user.group"
																		:options="groups"
																		:selectLabel="$t('common.select')"
																		:deselectLabel="$t('common.deselect')"
																		:placeholder="$t('users.group_placeholder')"
																		:multiple="false"
																		:class="{'is-invalid': submitted && $v.user.group.$error,}"
																		track-by="_id" 
																		label="name"
																		:showNoResults="false">
																	</multiselect>
																	<div
																		v-if="submitted && !$v.user.group.required"
																		class="invalid-feedback"
																		>
																		{{$t('users.group_required')}}
																		</div>
															</div>
														</div>
														
														<div class="col-12" v-if="!user._id">
																<div class="mb-3">
																		<label for="password">{{$t('users.password')}}*</label>
																		<input
																		id="password"
																		v-model="user.password"
																		type="password"
																		class="form-control"
																		:placeholder="$t('users.password_placeholder')"
																		:class="{
																				'is-invalid':
																				submitted && $v.user.password.$error,
																		}"
																		/>
																		<div
																		v-if="submitted && !$v.user.password.required"
																		class="invalid-feedback"
																		>
																		{{$t('users.password_required')}}
																		</div>
																</div>
														</div>
														<div class="col-12" v-if="!user._id">
														<div class="mb-3">
																<label for="repeat_password">{{$t('users.repeat_password')}}*</label>
																<input
																id="repeat_password"
																v-model="user.repeat_password"
																type="password"
																class="form-control"
																:placeholder="$t('users.repeat_password_placeholder')"
																:class="{
																		'is-invalid':
																		submitted && $v.user.repeat_password.$error,
																}"
																/>
																<div
																v-if="submitted && !$v.user.repeat_password.required"
																class="invalid-feedback">
																{{$t('users.repeat_password_required')}}
																</div>

																<div
																v-if="submitted && $v.user.repeat_password.required &&  !$v.user.repeat_password.sameAsPassword"
																class="invalid-feedback">
																{{$t('users.repeat_password_not_equals')}}
																</div>
														</div>
														</div>
												</div>

												<div class="text-end pt-5 mt-3">
														<b-button variant="light" @click="onCancelCreateOrEditUserClicked">{{$t('common.cancel')}}</b-button>
														<b-button type="submit" variant="primary" class="ms-1"
														>{{$t('common.confirm')}}</b-button>
												</div>
												</form>
										</b-modal>
										</div>
								</div>
								<!-- end col-->
								</div>
								<EmptyList :config="empty_list_config" v-if="!isLoading && users.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
								<EmptyList :config="empty_config" v-if="!isLoading && users.length == 0 && query ==''" @onButtonClicked="onCreateUserClicked" class="mt-3 mb-3"/>
						</div>
						<div class="table-responsive" v-if="!isLoading && users.length > 0">
								<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
										<thead class="table-light">
										<tr>
												<th scope="col" style="width: 70px;"></th>
												<th scope="col">{{$t('users.name')}}</th>
												<th scope="col">{{$t('users.email')}}</th>
												<th scope="col">{{$t('users.group')}}</th>
												<th scope="col"></th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="user in users" :key="user.id">
												<td>
												<div v-if="!user.imageURL" class="avatar-xs">
													<span class="avatar-title rounded-circle">{{user.name.charAt(0)}}</span>
												</div>
												<div v-if="user.imageURL">
														<img class="rounded-circle avatar-xs" :src="`${user.imageURL}`" alt />
												</div>
												</td>
												<td @click="onEditUserClicked(user)">
													<h5 class="font-size-14 mb-0">
															<a href="#" class="text-dark">{{user.name}}</a>
													</h5>
													<p class="text-muted mb-0 small">{{user.role}}</p>
												</td>
												<td @click="onEditUserClicked(user)">{{user.email}}</td>
												<td>{{ user.role == 'host' && user.group ? user.group.name : ""}}</td>
												<td class="text-end">
													<i class="fas fa-edit text-success me-2 interact" v-on:click="onEditUserClicked(user)"></i>
													<i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onDeleteUserClicked(user._id)"></i>
												</td>
										</tr>
										</tbody>
								</table>
								<b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveUserClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
										<p>{{$t('users.remove_host')}}</p>
								</b-modal>
								</div>
						</div>
						<div class="row mb-3" v-if="!isLoading && rows > perPage">
							<div class="col">
									<div class="dataTables_paginate paging_simple_numbers float-end">
											<ul class="pagination pagination-rounded mb-0">
													<b-pagination
															@input="onUserListPageClicked"
															v-model="currentPage"
															:total-rows="rows"
															:per-page="perPage"
													></b-pagination>
											</ul>
									</div>
							</div>
						</div>
					</div>
				</div>
	</Layout>
</template>
